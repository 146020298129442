<template>
  <div class="loginbox pop14">
    <h3>Login</h3>
    <form @submit.prevent="dologin">
      <div class="p-field p-grid">
        <label class="p-col-fixed" for="username" style="width:100px"
          >Gebruikersnaam</label
        >
        <div class="p-col">
          <input type="text" id="username" v-model="username" />
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" for="password" style="width:100px"
          >Wachtwoord</label
        >
        <div class="p-col">
          <input type="password" id="password" v-model="password" />
        </div>
      </div>
      <Button label="Login" type="submit" />
    </form>

    {{ errmsg }}
  </div>
</template>

<script>
import userService from "../services/user_service"

export default {
  data() {
    return {
      username: null,
      password: null,
      errmsg: null,
    }
  },
  userservice: null,
  created() {
    this.userservice = new userService()
  },
  methods: {
    dologin() {
      this.userservice
        .login(this.username, this.password)
        .then(() => {
          this.$store.commit("checkAuthentication")
          this.$router.push("/")
        })
        .catch((err) => {
          this.errmsg = err
        })
    },
  },
}
</script>

<style scoped>
.loginbox {
  background-color: white;
  border: solid black 1px;
  margin: 200px auto 0 auto;
  width: 30%;
  padding: 20px;
  color: black;
}
</style>

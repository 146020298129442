<template>
  <Card pt:content:class='card-content'>
    <template #header>
      <img :src="imgsrc" class="loading_card" />
      <div
        :class="
          `card_categorie ama18 ${
            record.categorie ? record.categorie.value : 'Beeldarchief'
          }`
        "
      >
        {{ record.categorie ? record.categorie.value : "Beeldarchief" }}
      </div>
    </template>
    <template #title>
      <div class="card-title">{{ record.title ? record.title.value : "geen titel" }}</div>
    </template>
    <template #subtitle>
      <div class="card-subtitle">{{ record.dated ? record.dated.value : "geen datum" }}</div>
    </template>
    <template #footer>
      <router-link
        class="more pop14"
        :to="`/collectie/${record.uniqid ? record.uniqid.value : '000'}`"
        >lees meer</router-link
      >
    </template>
  </Card>
</template>

<script>
import config from "../../config"

export default {
  props: ["record"],
  data() {
    return {
      imgsrc: "",
      categorie: "",
      title: "",
      subtitle: "",
      uniqid: "",
      dated: "",
    }
  },
  mounted() {
    if (this.record.image) {
      var myImage = new Image()
      myImage.src = `${config.iwib.server}/cci/197/0/${this.record.image.value}?background=FFFFFF&fetch=false`
      myImage.onload = () => {
        this.imgsrc = myImage.src
      }
      this.imgsrc = require("@/assets/images/image_loader_orange_197.svg")
    } else {
      this.imgsrc = require("@/assets/images/_noimage.png")
    }
  },
}
</script>

<style scoped>
.loading_card {
  width: 197px;
}
.Beeldarchief {
  background-color: #ef5e33;
}
.Documenten {
  background-color: #a154ae;
}
.Voorwerpen {
  background-color: #201022;
}
.Bibliotheek {
  background-color: #67a178;
}
.p-card {
  border-radius: 0px;
  color: #201022 !important
}
.card-title {
  font-family: Amaranth-Regular;
  font-size: 24px;
  margin-top: -20px;
}
.card-subtitle {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #201022 !important
}
.card-content {
  padding: 0px !important;
}
.card_categorie {
  position: relative;
  color: white;
  padding: 2px;
  top: -20px;
  left: 20px;
  width: 122px;
  text-align: center;
}
.more {
  color: #201022;
  font-weight: bold;
}

</style>

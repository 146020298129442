<template>
  <div class="content">
    <div class="menu p-d-flex p-jc-between" v-bind:class="{'green': $route.path!=='/' && !$route.path.includes('admin')}">
      <InlineSvg
        class="menubutton"
        :src="require('../assets/images/iwi.svg')"
        title="IWI"
        @click="goto('')"
      />
      <span class="ama20">IWI 2.0</span>
      <div class="menubutton pop20 p-d-flex p-ai-start">
        <div @click="visibleMenu = true">
          Menu&nbsp;&nbsp;<span class="pi pi-bars" />
        </div>
        <div
          v-show="authenticated"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          @click="toggleAdmin"
        >
          &nbsp;&nbsp;<span class="pi pi-cog" />
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>

  <Menu id="overlay_menu" ref="adminmenu" :model="items" :popup="true" />

  <Sidebar
    v-model:visible="visibleMenu"
    :baseZIndex="1000"
    position="full"
    class="sidebar"
    :showCloseIcon="false"
  >
    <div class="closemenu pop20" @click="visibleMenu = false">
      Sluit&nbsp;&nbsp;<span class="pi pi-times" />
    </div>
    <div class="p-d-flex p-jc-center">
      <div class="p-flex-column" style="margin-left: -200px;">
        <div class="menuentrycontainer">
          <router-link
            to="/tjalie"
            class="menuentry p-d-flex p-ai-start"
            @click="closeMenu()"
          >
            <div class="number pop20">01</div>
            <div class="item ama75">Tjalieclopedie</div>
          </router-link>
          <router-link
            to="/piekirpedia"
            class="menuentry p-d-flex p-ai-start"
            @click="closeMenu()"
          >
            <div class="number pop20">02</div>
            <div class="item ama75">Piekirpedia</div>
          </router-link>
          <router-link
            to="/eternite"
            class="menuentry p-d-flex p-ai-start"
            @click="closeMenu()"
          >
            <div class="number pop20">03</div>
            <div class="item ama75">Theatre Eternite</div>
          </router-link>
          <router-link
            to="/aangespoeld"
            class="menuentry p-d-flex p-ai-start"
            @click="closeMenu()"
          >
            <div class="number pop20">04</div>
            <div class="item ama75">Aangespoeld</div>
          </router-link>
          <router-link
            to="/collectie"
            class="menuentry p-d-flex p-ai-start"
            @click="closeMenu()"
          >
            <div class="number pop20">05</div>
            <div class="item ama75">IWI Collectie</div>
          </router-link>
        </div>
        <div class=" p-mt-auto">
          <div class="lowermenu p-d-flex p-jc-between">
            <router-link
              to="/about"
              class="lowermenuentry p-d-flex p-ai-start"
              @click="closeMenu()"
            >
              <div class="dash">&nbsp;</div>
              <div class="item pop20">
                Ondex meer over ons en de IWI collectie 2.0
              </div>
            </router-link>
            <router-link
              to="/contact"
              class="lowermenuentry p-d-flex p-ai-start"
              @click="closeMenu()"
            >
              <div class="dash">&nbsp;</div>
              <div class="item pop20">Neem contact met ons op</div>
            </router-link>
            <router-link
              to="/login"
              class="lowermenuentry p-d-flex p-ai-start"
              @click="closeMenu()"
            >
              <div class="dash">&nbsp;</div>
              <div class="item pop20">Login</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>

  <!--       <img src='/images/test.jpg' /> 
 -->
</template>

<script>
import userService from "../services/user_service"
import config from "../../config"

export default {
  watch: {
    $route(to, from) {
      console.log(this.$route.path)
      console.log("Route changed from " + from.path + " to " + to.path)
    },

    "$store.state.authenticated": function() {
      this.authenticated = this.$store.state.authenticated
    },
  },
  mounted() {
    window.addEventListener('click', event => {
    // ensure we use the link, in case the click has been received by a subelement
    let { target } = event
    while (target && target.tagName !== 'A') target = target.parentNode
    // handle only links that do not reference external resources
    if (target && target.matches("a:not([href*='://'])") && target.href) {
      // some sanity checks taken from vue-router:
      // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
      const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
      // don't handle with control keys
      if (metaKey || altKey || ctrlKey || shiftKey) return
      // don't handle when preventDefault called
      if (defaultPrevented) return
      // don't handle right clicks
      if (button !== undefined && button !== 0) return
      // don't handle if `target="_blank"`
      if (target && target.getAttribute) {
        const linkTarget = target.getAttribute('target')
        if (/\b_blank\b/i.test(linkTarget)) return
      }
      // don't handle same page links/anchors
      const url = new URL(target.href)
      const to = url.pathname
      if (window.location.pathname !== to && event.preventDefault) {
        event.preventDefault()
        this.$router.push(to.replace(config.url_base, ""))
      }
    }
  })
  },
  data() {
    return {
      authenticated: this.$store.state.authenticated,
      visibleMenu: false,
      visibleAdmin: false,
      items: [
            {
              label: "Aangespoeld",
              to: "/admin/aangespoeld",
            },
            {
              label: "Eternite",
              to: "/admin/eternite",
            },
            {
              label: "Gebruikers",
              to: "/admin/users",
            },
            {
              label: "Old Tjalie",
              to: "/admin/tjalie",
            },
            {
              label: "Old Tjalie points",
              to: "/admin/tjalie_points",
            },
            {
              label: "Old Piekir",
              to: "/admin/piekirpedia",
            },
            {
              label: "Old Piekir points",
              to: "/admin/piekir_points",
            },
            {
              label: "Uitloggen",
              command: () => {
                this.logout()
              },
            }
      ]
    }
  },
  userservice: null,
  created() {
    this.userservice = new userService()
  },
  methods: {
    toggleAdmin(event) {
      this.$refs.adminmenu.toggle(event)
    },
    goto(page) {
      this.visibleMenu = false
      this.$router.push(`/${page}`)
    },
    closeMenu() {
      this.visibleMenu = false
    },
    closeAdmin() {
      this.visibleAdmin = false
    },
    /*     isAuthenticated() {
      return Boolean(localStorage.getItem("user"))
    },
 */ logout() {
      this.userservice.logout()
      this.visibleAdmin = false
      this.$store.commit("checkAuthentication")
      this.$router.go()
    },
  },
}

      // items: [
      //   {
      //     label: "Records",
      //     items: [
      //       {
      //         label: "Aangespoeld",
      //         to: "/admin/aangespoeld",
      //       },
      //       {
      //         label: "Eternite",
      //         to: "/admin/eternite",
      //       },
      //     ],
      //   },
      //   {
      //     label: "Tjalieclopedie",
      //     items: [
      //       {
      //         label: "Points",
      //         to: "/admin/tjalie_points",
      //       },
      //       {
      //         label: "Records",
      //         to: "/admin/tjalie",
      //       },
      //     ]
      //   },
      //   {
      //     label: "Piekirpedia",
      //     items: [
      //       {
      //         label: "Points",
      //         to: "/admin/piekir_points",
      //       },
      //       {
      //         label: "Records",
      //         to: "/admin/piekirpedia",
      //       },
      //     ]
      //   },
      //   {
      //     label: "Other",
      //     items: [
      //       {
      //         label: "Lifelines",
      //         to: "/lifelines",
      //       },
      //       {
      //         label: "Users",
      //         to: "/admin/users",
      //       },
      //       {
      //         label: "Logout",
      //         command: () => {
      //           this.logout()
      //         },
      //       },
      //     ],
      //   },
      // ],

</script>

<style>
@font-face {
  font-family: "Amaranth-Regular";
  src: url("../assets/fonts/Amaranth-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../assets/fonts/Poppins-Regular.ttf");
}
body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: #201022;
}
.tox-notifications-container {display: none !important;}
.green_bg {
  background-color: #67a178;
}
.purple_bg {
  background-color: #201022;
}
.content {
  /*position: relative;*/
  /* margin: auto;
    width: 1440px;
    background: #67A178; */
  color: white;
}
.ama14 {
  font-family: Amaranth-Regular;
  font-size: 14px;
}
.ama16 {
  font-family: Amaranth-Regular;
  font-size: 16px;
}
.ama18 {
  font-family: Amaranth-Regular;
  font-size: 18px;
}
.ama20 {
  font-family: Amaranth-Regular;
  font-size: 20px;
}
.ama22 {
  font-family: Amaranth-Regular;
  font-size: 22px;
}
.ama25 {
  font-family: Amaranth-Regular;
  font-size: 25px;
}
.ama30 {
  font-family: Amaranth-Regular;
  font-size: 30px;
}
.ama35 {
  font-family: Amaranth-Regular;
  font-size: 35px;
}
.ama40 {
  font-family: Amaranth-Regular;
  font-size: 40px;
}
.ama60 {
  font-family: Amaranth-Regular;
  font-size: 60px;
}
.ama75 {
  font-family: Amaranth-Regular;
  font-size: 75px;
}
.pop14 {
  font-family: Poppins-Regular;
  font-size: 14px;
}
.pop16 {
  font-family: Poppins-Regular;
  font-size: 16px;
}
.pop18 {
  font-family: Poppins-Regular;
  font-size: 18px;
}
.pop20 {
  font-family: Poppins-Regular;
  font-size: 20px;
}
.pop24 {
  font-family: Poppins-Regular;
  font-size: 24px;
}
.closemenu {
  text-align: right;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}
.menu {
  position: fixed;
  top: 10px;
  left: 0;
  margin: 5px;
  /*height: 30px;*/
  width: 99%;
  padding: 5px;
  color: white;
  font-size: 20px;
  z-index: 100;
  background-color: #201022;
}
.green {
  background-color: #67a178 !important;
}
.menubutton {
  cursor: pointer;
  margin-left: 15px;
}
.mapboxgl-ctrl-top-right {
  top: 85px;
}
.sidebar {
  /*background: #201022;*/
  background: url("../assets/images/purplemapbackground.jpg") no-repeat center
    fixed;
  background-size: cover;
}
.menuentrycontainer {
  /* position: absolute; */
  margin-top: 80px;
}
.menuentrycontainer a {
  text-decoration: none;
}
.menuentry {
  /* margin-left: 150px; */
  padding-bottom: 50px;
  opacity: 0.3;
}
.menuentry.router-link-active {
  opacity: 1;
}
.menuentry:hover {
  opacity: 1;
}
.menuentry .number {
  color: #ef5e33;
  font-weight: 400;
  line-height: 30px;
  margin-top: 38px;
  padding-right: 10px;
}
.menuentry .item {
  color: #ffffff;
  font-weight: 400;
  line-height: 65px;
  cursor: pointer;
}
.lowermenu a {
  text-decoration: none;
  margin-top: 50px;
}
.lowermenu .item {
  width: 230px;
  color: #ffffff;
  font-weight: 400;
  line-height: 22px;
  padding-left: 12px;
}
.lowermenu .dash {
  width: 29.71px;
  height: 1px;
  border: solid 1px #ef5e33;
  margin-top: 5px;
}
.lowermenuentry {
  padding-right: 78px;
  text-decoration: none;
  opacity: 0.3;
}
.lowermenuentry:hover {
  opacity: 1;
}
.lowermenuentry.router-link-active {
  opacity: 1;
}

.dt {
  margin-top: 50px;
}
.p-component {
  font-family: Poppins-Regular;
  font-size: 14px;
}
</style>

<template>
  <div class="record">
    <div class="backlink ama18">
      <a @click="$router.go(-1)"
        ><img :src="require('@/assets/images/leftarrow.svg')" />{{ $route.name }}</a
      >
    </div>
    <div class="topimage">
      <img
        :src="
          `${$config.images.server}/images/${record.media}?width=${windowWidth}&height=590&fit=cover&background=ffffff&amp;position=left%20top`
        "
      />
    </div>
    <div class="data">
      <div class="keywords ama18">
        <span class="keyword" v-for="keyword in keywords" :key="keyword">
          {{ keyword }}
        </span>
      </div>
      <div class="title ama60">
        {{ record.title }}
      </div>
      <div class="subtitle ama40">
        {{ record.subtitle }}
      </div>
      <div class="description pop24">
        <span v-html="record.description" />
      </div>
      <div v-for="media in medias" :key="media.name">
        <div v-if="media.type == 'video/mp4'">
          <video width="320" height="240" controls>
            <source :src="`${$config.images.server}/videos/${media.name}`" />
          </video>
        </div>
        <div v-if="media.type.includes('audio')">
          <audio controls>
            <source :src="`http://localhost:4000/audios/${media.name}`" />
          </audio>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import recordService from "../services/record_service"

export default {
  props: ["path", "type"],
  data() {
    return {
      record: {},
      windowWidth: (this.windowWidth = window.innerWidth),
      keywords: {},
      medias: [],
    }
  },
  recordservice: null,
  created() {
    this.recordservice = new recordService()
  },
  mounted() {
    //this.recordservice.getById(this.$router.currentRoute.value.params.id).then(data => this.record = data);
    var t = this
    this.recordservice.getBySlug(this.path, this.type).then((data) => {
      t.record = data
      t.keywords = t.record.keywords ? t.record.keywords.split(";") : ""
      t.medias = JSON.parse(t.record.relatedmedia)
    })
    //if (!this.record.title) this.$router.push("/404")
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {},
}
</script>

<style scoped>
.record {
  width: 100%;
  background-color: #201022;
  color: white;
}
.topimage {
  height: 590px;
}
.data {
  margin-left: 294px;
  margin-top: 35px;
  margin-right: 294px;
}
.title {
  margin-top: 20px;
}
.keyword {
  background-color: #ef5e33;
  height: 50px;
  width: fit-content;
  padding: 11px;
  cursor: pointer;
  margin: 30px 20px 20px 0px;
}
.backlink {
  position: absolute;
  top: 104px;
  left: 69px;
  cursor: pointer;
}
.backlink img {
  margin-right: 10px;
}
</style>
